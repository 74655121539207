// extracted by mini-css-extract-plugin
export var about = "index-module--about--ee3c6";
export var aboutContents = "index-module--aboutContents--4c1dd";
export var comingSoon = "index-module--comingSoon--b56cc";
export var deadLink = "index-module--deadLink--777b2";
export var dlLinks = "index-module--dlLinks--1b10e";
export var dlText = "index-module--dlText--40f0a";
export var feature = "index-module--feature--fe01d";
export var featureContents = "index-module--featureContents--c5f1b";
export var firstView = "index-module--firstView--61e19";
export var firstViewContents = "index-module--firstViewContents--673c7";
export var firstViewFooter = "index-module--firstViewFooter--2e88e";
export var firstViewHeaderImg = "index-module--firstViewHeaderImg--8df7a";
export var firstViewTitle = "index-module--firstViewTitle--233fe";
export var footerContents = "index-module--footerContents--9a599";
export var gifImg = "index-module--gifImg--700ba";
export var imgContainer = "index-module--imgContainer--1f2b1";
export var imgItem = "index-module--imgItem--93017";
export var imgWrap = "index-module--imgWrap--aef1a";
export var infoTextList = "index-module--infoTextList--5fa11";
export var inner = "index-module--inner--dc8bd";
export var innerImages = "index-module--innerImages--7c6f5";
export var innerItem = "index-module--innerItem--eeb3b";
export var innerItemImg = "index-module--innerItemImg--01766";
export var innerItemTextList = "index-module--innerItemTextList--94af4";
export var innerItemTitle = "index-module--innerItemTitle--a8d03";
export var innerTextList = "index-module--innerTextList--82afe";
export var install = "index-module--install--55148";
export var installContents = "index-module--installContents--565ac";
export var installStart = "index-module--installStart--26c1c";
export var jpTitle = "index-module--jpTitle--062d8";
export var leftImg = "index-module--leftImg--f59f2";
export var rightImg = "index-module--rightImg--64018";
export var title = "index-module--title--026c7";
export var titleSvg = "index-module--titleSvg--34695";
export var topImg = "index-module--topImg--61c71";
export var underImg = "index-module--underImg--8be10";
export var underImgWrap = "index-module--underImgWrap--3107d";
export var underText = "index-module--underText--56b6f";