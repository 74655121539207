import React from "react"
import Layout from "topPageLayout"
import PcContents from "components/pc/sharePage/index"
import SpContents from "components/sp/sharePage/index"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useGetStores } from "hooks/useGetStores"
import { FilterAppStores } from "services/FilterAppStores"

// markup
const IndexPage: React.FC = () => {
  const breakPoints = useBreakpoint()
  const stores = useGetStores();
  const appStores = FilterAppStores(stores);
    return (
      <Layout>
        {breakPoints.pc &&
          <PcContents stores={appStores} />
        }
        {breakPoints.sp &&
          <SpContents stores={appStores} />
        }
      </Layout>
    )
}

export default IndexPage
